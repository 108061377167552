@font-face {
  font-family: "icomoon";
  src: url(/./fonts/icomoon.ee8fbcb18b4251ce079e6c2e8fcb8dbc.eot);
  src: url(/./fonts/icomoon.ee8fbcb18b4251ce079e6c2e8fcb8dbc.eot#iefix) format("embedded-opentype"),
    url(/./fonts/icomoon.d78a34c48bf6d95f48fc15d540fd08a1.ttf) format("truetype"),
    url(/./fonts/icomoon.844d4e93af0dcb55dd07e3a7e5625785.woff) format("woff"),
    url(/./fonts/icomoon.321f82ada94d17293b83fed9c02bb6de.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-business-partner:before {
  content: "\e900";
}
.icon-button_tick:before {
  content: "\e901";
}
.icon-coeur-plein:before {
  content: "\e902";
}
.icon-communuaute:before {
  content: "\e903";
}
.icon-conseil:before {
  content: "\e904";
}
.icon-human-partner:before {
  content: "\e905";
}
.icon-map_pointer:before {
  content: "\e906";
}
.icon-mdp-cache:before {
  content: "\e907";
}
.icon-mdp-vu:before {
  content: "\e908";
}
.icon-photo:before {
  content: "\e909";
}
.icon-settings:before {
  content: "\e90a";
}
.icon-technpartner:before {
  content: "\e90b";
}
.icon-transparence:before {
  content: "\e90c";
}
.icon-trash:before {
  content: "\e90d";
}
.icon-activite:before {
  content: "\e90e";
}
.icon-attente:before {
  content: "\e90f";
}
.icon-attention:before {
  content: "\e910";
}
.icon-bulle:before {
  content: "\e911";
}
.icon-calendrier:before {
  content: "\e912";
}
.icon-check_1:before {
  content: "\e913";
}
.icon-check_2:before {
  content: "\e914";
}
.icon-check:before {
  content: "\e915";
}
.icon-client.path1:before {
  content: "\e916";
  color: rgb(22, 53, 103);
}
.icon-client.path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-close:before {
  content: "\e918";
}
.icon-compte:before {
  content: "\e919";
}
.icon-croix:before {
  content: "\e91a";
  color: rgb(37, 44, 50);
}
.icon-cv_1:before {
  content: "\e91c";
}
.icon-cv:before {
  content: "\e91d";
}
.icon-dashboard:before {
  content: "\e91f";
}
.icon-date:before {
  content: "\e920";
}
.icon-deconnexion:before {
  content: "\e921";
}
.icon-dispo:before {
  content: "\e922";
}
.icon-doc:before {
  content: "\e923";
}
.icon-download:before {
  content: "\e924";
}
.icon-edit-non:before {
  content: "\e925";
}
.icon-edit:before {
  content: "\e926";
}
.icon-email:before {
  content: "\e927";
}
.icon-enregistrer:before {
  content: "\e928";
}
.icon-envoye:before {
  content: "\e929";
}
.icon-etat:before {
  content: "\e92a";
}
.icon-etps:before {
  content: "\e92b";
}
.icon-fichier:before {
  content: "\e92c";
}
.icon-filtre:before {
  content: "\e92d";
}
.icon-fleche-gauche:before {
  content: "\e92e";
}
.icon-fleche:before {
  content: "\e92f";
}
.icon-freelance:before {
  content: "\e930";
}
.icon-iconxp:before {
  content: "\e931";
}
.icon-image:before {
  content: "\e932";
}
.icon-incomplet-copy:before {
  content: "\e933";
}
.icon-info:before {
  content: "\e934";
}
.icon-inscription:before {
  content: "\e935";
}
.icon-leche-droite:before {
  content: "\e936";
}
.icon-lien-source-1:before {
  content: "\e937";
}
.icon-lien-source-2:before {
  content: "\e938";
}
.icon-lieu:before {
  content: "\e939";
}
.icon-liste:before {
  content: "\e93a";
}
.icon-loading:before {
  content: "\e93b";
}
.icon-mission:before {
  content: "\e93c";
}
.icon-modification:before {
  content: "\e93d";
}
.icon-moins:before {
  content: "\e93e";
}
.icon-notif:before {
  content: "\e93f";
}
.icon-oeil-ferme:before {
  content: "\e940";
}
.icon-oeil:before {
  content: "\e941";
}
.icon-plus_1:before {
  content: "\e942";
}
.icon-profil:before {
  content: "\e943";
}
.icon-proposition:before {
  content: "\e944";
}
.icon-refus:before {
  content: "\e945";
}
.icon-rm:before {
  content: "\e946";
}
.icon-search-copie:before {
  content: "\e947";
}
.icon-search .path1:before {
  content: "\e948";
  color: rgb(22, 53, 103);
}
.icon-search .path2:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-securite:before {
  content: "\e94a";
}
.icon-stat:before {
  content: "\e94b";
}
.icon-tjm:before {
  content: "\e94c";
}
.icon-xp:before {
  content: "\e94d";
}
